body{
  overflow-x: hidden;
}

.error{
    color: #dc3545;
}

.sidebar-offcanvas ul{
  padding-top: 0 !important;
}
.bord-design .sidebar-offcanvas {

  position: sticky;
  top: 0;
  min-height: 350px;
  max-height: 440px;
  overflow: auto;
  height: auto;
  padding-bottom: 25px;
}
.slick-slider button::before{
  color: #1f62c7;
  font-size: 30px;
}
.colorAlgoone{
  background-color: #f8fbfd;
  padding-top: 80px;
}
.modal-content{
  margin-top: 80px;
}